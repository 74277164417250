import React from 'react';
import { BuilderComponent, Builder } from '@builder.io/react/lite';
import { useRouter } from 'next/router';
interface BuilderHOCProps {
  content: any;
  model?: string | null;
  data?: Object;
}

const BuilderHOC = (props: BuilderHOCProps) => {
  const router = useRouter();
  const { model: modelUrlParam } = router.query;

  // console.log(JSON.stringify(props, undefined, 2));

  return (
    <>
      {props.content || Builder.isPreviewing || Builder.isEditing ? (
        <BuilderComponent
          content={props.content}
          data={props.data} // pass object to be available in Builder bindings as state.keyName
          model={props.model || (modelUrlParam as string) || 'page'} // model impacts preview authoring
        />
      ) : (
        <h1 style={{ textAlign: 'center' }}>
          Loading...
        </h1> /* Loading? Should it be custom 404 page? */
      )}
    </>
  );
};

export default BuilderHOC;
