import { GetStaticProps, GetStaticPaths } from 'next';
import type { I18n } from 'next-i18next';
import { withTranslation } from 'next-i18next';
import { builder } from '@builder.io/react/lite';
import BuilderHOC from '@builder/BuilderHOC';
import getPathsFromBuilder from '@helpers/getPathsFromBuilder';
import { getBuilderStaticProps } from '@helpers/getBuilderStaticProps';
import { LocaleEnum } from '@store/enums';

interface pageProps {
  content: any;
  i18n: I18n;
  t: Function;
  path: string;
}

function Page(props: pageProps) {
  return (
    <>
      <BuilderHOC {...props} model={builder.editingModel} />
    </>
  );
}

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  const paths = await getPathsFromBuilder(
    'page',
    locales as LocaleEnum[],
    'page',
    {
      isCatchAllRoute: true,
    }
  );

  return {
    paths,
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async ({
  params,
  defaultLocale,
  locale,
}) => {
  // format page URL to align with NextJS's paradigm for nested URLs
  let formattedPageUrl = params?.page;
  if (params?.page && Array.isArray(params?.page)) {
    if (params?.page.length > 1) {
      formattedPageUrl = params?.page.join('/');
    }
  }

  // handle `/` as homepage
  formattedPageUrl = formattedPageUrl ? formattedPageUrl : '';

  // reference page model with locale
  const builderLocalizedPageModel =
    defaultLocale === locale ? 'page' : `page-${locale}`;

  // reference page URL with locale
  const builderLocalizedPageUrl =
    defaultLocale === locale
      ? `/${formattedPageUrl}`
      : `/${locale}/${formattedPageUrl}`;

  return await getBuilderStaticProps(
    locale as LocaleEnum,
    builderLocalizedPageModel,
    builderLocalizedPageUrl
  );
};

export default withTranslation('common')(Page);
